export default class NewsSidebar {
  constructor() {
    if ($('.sidebar-content-news').length) {
      $(window).scroll(() => this.handleSidebar(
        $('.sidebar-content-news'),
        $('.sidebar-content-news-widget')
      )).scroll();
    }

    if ($('.post-v2-sidebar').length) {
      const sidebarStartSelector = $('.widget-breaking_news').length
        ? $('.widget-breaking_news')
        : $('#sidebar-post-v2');

      $(window).scroll(() => this.handleSidebar(
        sidebarStartSelector,
        $('.sidebar-content-news-widget-postv2'),
        80
      )).scroll();
    }
  }

  handleSidebar(sidebarSelector, widgetSelector, offset = 0) {
    const sidebarOffset = sidebarSelector.length ? sidebarSelector.offset() : null;
    
    if (!sidebarOffset) return; 

    const sidebarStart = sidebarOffset.top - offset;
    const scroll = $(document).scrollTop();
    const footerStart = $('#footer').length ? $('#footer').offset().top : null;

    if (!scroll || !footerStart) return;

    if (scroll > sidebarStart && scroll < (footerStart - 800)) {
      widgetSelector.addClass('active');
    } else {
      widgetSelector.removeClass('active');
    }
  }
}